<template>

  <el-dialog :visible.sync='dialogVisible' v-bind='$attrs' v-on='$listeners'
             :title='title' width='1000px' @close='onClose' append-to-body>

    <div style='width: 800px;margin: 10px auto;border: #f2f2f2 1px dashed;padding: 10px;border-radius: 4px'>
      <div class='flex-sb'>
        <el-link type='primary' @click='getInfo' style='font-size: 16px;'>{{ searchCondition.year
          }}年第{{ searchCondition.quarter
          }}季度《{{ searchCondition.dept_name }}》
        </el-link>
        <!--        <el-button size='mini' type='text' @click='showMoreEvent'-->
        <!--                   :icon="!showMore?'el-icon-caret-bottom':'el-icon-caret-top'">更多-->
        <!--        </el-button>-->
      </div>
      <div>
        <!--          <div style='display: flex;flex-direction: row;align-items: center;gap: 5px;margin-bottom: 8px'>-->

        <!--            <artist-search @handleSelect='handleSelectArtist'></artist-search>-->
        <!--            <el-button type='primary' @click='handleAdd' :disabled='loadingAdd' :loading='loadingAdd'>添加</el-button>-->
        <!--            <el-tooltip class='item' effect='light' content='可后添加或部门总监列表批量追加' placement='right'>-->
        <!--              <span class='el-icon-question'></span>-->
        <!--            </el-tooltip>-->
        <!--          </div>-->
        <div style='padding: 5px;margin-top: 10px'>
          <template v-for='(kolGroup,index) in kol_list'>
            <div :key='`group_${index}`' class='group-box'>
              <div>
                {{ kolGroup.dept_name || '' }}：
                <!--                <el-tag effect='plain' size='small' type='info'>{{ kolGroup.dept_name || '' }}</el-tag>-->
              </div>
              <div v-if='kolGroup.group_setting'>
                <el-tag size='small' effect='plain' v-for='(kolInfo,k) in kolGroup.group_setting'
                        :key='`kol_${index}_${k}`'
                        :type='`${showRepeat(kolInfo)>1?"danger":"success"}`'
                        style='margin-bottom: 5px;margin-right: 5px'>
                  {{ kolInfo.nickname || '' }}
                  <span title='出现在多个考核组中' style='color: #ff4949;font-size: 1.1em' v-if='showRepeat(kolInfo) >1'>
                    - {{ showRepeat(kolInfo) }}
                  </span>
                </el-tag>
              </div>
              <div v-if='kolGroup.group_setting'>
                （{{ kolGroup.group_setting.length || 0 }} 个）
              </div>

            </div>
          </template>
        </div>
        <div class='tip-note'><span style='color: red'>*</span> 红人信息来自项目组的红人管理</div>

      </div>
      <div v-if='searchCondition.id' class='flex-evenly' style='gap: 20px;margin: 10px 20px'>
        <div style='text-align: center'>
          <span style='font-size: 1.2em'>总监：{{ currentEditRow.manager_name || '' }}</span>
          &nbsp;
          <el-link type='primary' icon='el-icon-edit-outline'
                   @click='handleEditManager(currentEditRow)'></el-link>
        </div>
        <div>
          <el-button round type='primary' icon='el-icon-refresh' :loading='loadingInit' :disabled='loadingInit'
                     @click='handleInit'>初始化营收绩效目标
          </el-button>
        </div>

      </div>

      <div style='padding: 10px;border: #f2f2f2 1px solid;'>
        <h3>参与考核的红人</h3>
        <el-table :data='kol_range' stripe highlight-current-row>
          <el-table-column label='序号' type='index' width='50'></el-table-column>
          <el-table-column label='红人昵称' min-width='240px' show-overflow-tooltip>
            <template slot-scope='{row}'>
              <span :title='`${row.artist_id}`'>{{ row.nickname || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column width='80px'>
            <template slot-scope='{row}'>
              <el-link type='danger' icon='el-icon-close' @click='handleRemoveKol(row)'></el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!--    修改总监-->
    <SelectManager ref='refSelectManager' @handleSelect='handleSelectManager'></SelectManager>
  </el-dialog>

</template>

<script>
import SelectManager from '@/pages/pa/components/SelectManager'
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'DeptKolView',
  components: { ArtistSearch, SelectManager },
  data() {
    return {
      dialogVisible: false,
      loadingStatus: false,
      loadingAdd: false,
      showMore: false,
      loadingInit: false,
      title: '部门概览',
      searchCondition: {},
      currentEditRow: {},
      deptList: [],
      dataList: [],
      pageData: { page_size: 100, current_page: 1 },
      total: 0,
      selection: [],
      kolList: [],
      selectionKol: [],
      selectedKol: [],
      checkList: ['Y'],
      kol_list: [],
      kol_range: [],
      kolMap: {},
      currentKol: {}
    }
  },
  methods: {
    show(row) {
      this.dialogVisible = true
      this.showMore = false
      this.searchCondition = row
      this.kol_range = []
      this.kolList = []
      this.checkList = ['Y']
      this.selectedKol = []
      // this.kol_range = this.searchCondition.kol_range || []
      this.getInfo()

    },
    onClose(){
      this.$emit('closed')
    },
    async getNextDeptList() {
      if (this.searchCondition.dept_id) {
        let { list } = await this.$api.getNextDeptList(this.searchCondition.dept_id)
        this.deptList = list || []
      }

    },
    showMoreEvent() {
      this.showMore = !this.showMore
      if (this.showMore)
        this.getNextDeptList()
    },
    async handleSave(formData) {

      // if (!this.searchCondition.manager_id) {
      //   this.$notify.warning('总监必填')
      //   return
      // }
      if (!this.searchCondition.dept_id) {
        this.$notify.warning('部门必选')
        return
      }
      if (!this.searchCondition.quarter) {
        this.$notify.warning('季度必选')
        return
      }
      if (!this.searchCondition.year) {
        this.$notify.warning('年份必选')
        return
      }
      if (!formData.nickname) {
        this.$notify.warning('红人必选')
        return
      }
      if (!formData.id) {
        this.$notify.warning('红人ID必传')
        return
      }
      // 考核设置ID
      let data = {
        // id: this.searchCondition.id,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        dept_id: this.searchCondition.dept_id,
        artist_id: formData.id,
        nickname: formData.nickname,
        group_id: formData.group_id,
        group_name: formData.group_name,
        sign_status: formData.sign_status,
        sign_start: formData.sign_start
      }
      await this.$api.appendPaChiefSettingKol(data)

      this.selectedKol.push(formData)
    },
    async handleSaveSelection(selectionKol) {
      //批量添加
      if (selectionKol.length < 100) {
        let multiple = selectionKol.map(formData => {
          return {
            // id: this.searchCondition.id,
            year: this.searchCondition.year,
            quarter: this.searchCondition.quarter,
            dept_id: this.searchCondition.dept_id,
            artist_id: formData.id,
            nickname: formData.nickname,
            group_id: formData.group_id,
            group_name: formData.group_name,
            sign_status: formData.sign_status,
            sign_start: formData.sign_start
          }
        })
        await this.$api.appendPaChiefSettingKol({ multiple: multiple, searchCondition: this.searchCondition })

      } else {

        //追个添加
        this.selectedKol = []
        const promises = selectionKol.map(item => {
          return this.handleSave(item)
        })
        await Promise.all(promises)
        this.$notify.success('已追加完毕')
      }

      this.$emit('saved')
      this.dialogVisible = false


    },
    handleSelectionChange(val) {
      this.selection = val
      this.getKolList()
    },
    async getKolList() {
      if (this.selection && this.selection.length === 0) {
        return
      }
      this.kolList = []
      let deptIds = this.selection.map((item) => {
        return item.dept_id
      })
      if (deptIds.length > 0) {

        let { list } = await this.$api.getPaDeptKolList({ dept_ids: deptIds, sign_status: this.checkList })
        this.kolList = list || []
      }

    },
    handleKolChange(val) {
      this.selectionKol = val
    },
    handleCommit() {
      if (this.selectionKol.length === 0) {
        this.$notify.warning('请先勾选考核红人')
        return
      }
      let msg = `确认将选中的【${this.selectionKol.length}】个红人添加为《${this.searchCondition.dept_name}》考核红人`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.handleSaveSelection(this.selectionKol)
      }).catch(() => {
        this.$notify.info('取消')
      })
    },
    handleRemoveKol(row) {
      if (!row.artist_id) {
        this.$message.warning('红人ID为空')
        return
      }
      this.$confirm(`确认移除考核红人【${row.nickname}】及【当前部门】总监营收绩效目标`, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        let posIndex = this.kol_range.indexOf(row)
        this.kol_range.splice(posIndex, 1)
        await this.$api.deletePaChiefSettingKol({ id: this.searchCondition.id, artist_id: row.artist_id })
        // 删除之后刷新数据
        this.$emit('saved')

      }).catch(() => {
        this.$notify.info('取消操作')
      })

    },
    handleRemoveRow(row) {
      let index = this.kolList.indexOf(row)
      this.kolList.splice(index, 1)
    },
    handleSelectArtist(obj) {
      if (obj && obj.id)
        this.currentKol = {
          id: obj.id,
          nickname: obj.nickname,
          group_id: obj.group_id,
          group_name: obj.group_name,
          sign_start: obj.sign_start,
          sign_status: obj.sign_status
        }
    },
    appendToKolRange() {
      if (this.currentKol.id) {
        let obj = { ...this.currentKol }
        if (this.kolList.findIndex(value => value.id === obj.id) === -1) {
          this.kolList.push(obj)
        }
      }
    },
    async handleAdd() {
      this.loadingAdd = true
      setTimeout(() => {
        this.loadingAdd = false
      }, 2000)
      await this.handleSave(this.currentKol)
      this.loadingAdd = false
      await this.getInfo()
      this.currentKol = {}
      await this.handleInit()
    },
    async getInfo() {
      let { info } = await this.$api.loadPaDeptKolList(this.searchCondition)
      this.kol_range = info.kol_range || []
      this.kol_list = info.groups || []
      this.searchCondition.id = info.id
      this.currentEditRow = info || {}
      this.kolMap = {}
      this.kol_list.forEach((kolGroup) => {
        if (kolGroup.group_setting && kolGroup.group_setting.length > 0) {
          kolGroup.group_setting.forEach((item) => {
            if (this.kolMap[item.artist_id]) {
              this.kolMap[item.artist_id] += 1
            } else
              this.kolMap[item.artist_id] = 1
          })
        }
      })
    },
    async handleInit() {
      this.loadingInit = true
      // this.handleInitItem(this.searchCondition)
      await this.$api.initPaChiefRevenue(this.searchCondition)
      setTimeout(() => {
        this.loadingInit = false
      }, 1000)
      await this.getInfo()
    },
    async handleInitItem(row) {
      this.loadingInit = true
      setTimeout(() => {
        this.loadingInit = false
      }, 2000)
      let { err, success, tag_total, exist_total } = await this.$api.initItemPaChiefRevenue(row.id)
      if (err) {
        if (err.length > 0) {
          let errMsgArr = err.map(item => {
            return item.err_msg
          })
          this.$message.warning(errMsgArr.join('、'))
        }
        this.$notify.success(`本次初始化${success}条，考核项${tag_total}条，已初始化${exist_total}条`)

      } else {
        this.$notify.success('初始化完毕')
      }

    },
    handleEditManager(row) {
      this.currentEditRow = row
      this.$notify.success('修改总监')
      this.$refs['refSelectManager'].show()
    },
    async handleSelectManager(val) {
      if (val && val.user_id) {
        // let posIndex = this.dataList.indexOf(this.currentEditRow)
        // this.dataList[posIndex]['manager_id'] = val.user_id
        // this.dataList[posIndex]['manager_name'] = val.nickname
        await this.$api.editPaDeptSettingManager({
          id: this.currentEditRow.id,
          manager_id: val.user_id,
          manager_name: val.nickname
        })
        await this.getInfo()
      }

    },
    //显示出现次数大于1次的出现次数
    showRepeat(kolInfo) {
      const num = kolInfo.artist_id ? (this.kolMap[kolInfo.artist_id] || 0) : 0
      return num > 1 ? num : ''

    }
  }
}
</script>

<style scoped>
.group-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  border: #f2f2f2 1px dashed;
  padding: 10px 20px;
  margin-bottom: 5px;
}

.tip-note {
  padding: 5px 20px;
  margin-bottom: 5px;
  /*font-weight: 300;*/
  font-size: 12px;
  border-radius: 10px;
  /*border: #e97ca1 1px dashed;*/
  display: inline-block;
  line-height: 20px;

}
</style>
