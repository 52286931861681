<template>
  <div class='main-page-content'>
    <div class='pa-chief'>
      <div >
        <el-form :model='searchCondition' :inline='true' size='mini'>
          <div class='flex-sb'>
            <div>
              <el-form-item>
                <el-date-picker
                  v-model='searchCondition.year'
                  type='year'
                  format='yyyy年'
                  value-format='yyyy'
                  placeholder='选择年度' @change='changeQuarter' style='width: 120px'>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <QuarterSelect size='mini' :quarter.sync='searchCondition.quarter'
                               @change='changeQuarter'></QuarterSelect>
              </el-form-item>
              <el-form-item>
                <DeptSelectTree :onlyDept='true' :searchCondition='searchCondition' ref='deptTree' style='width: 360px'
                                @handleSelect='handleChangeDept'></DeptSelectTree>
              </el-form-item>

              <el-form-item>
                <el-button type='primary' @click='handleQuery' icon='el-icon-search'>查询</el-button>

                <el-button type='default' @click='reset'>重置</el-button>
<!--                <el-button type='warning' @click='showInitSetting' :disabled='loadingSync' :loading='loadingSync'>初始化-->
<!--                </el-button>-->
              </el-form-item>

              <el-form-item v-if='searchCondition.release_status==="N"'>
                <el-button type='primary' icon='el-icon-setting' @click='showDeptKolSetting'>部门概览</el-button>
              </el-form-item>
              <el-form-item>
                <export-link ref='export' @export='exportExcel' class-name='export-btn'
                             :can-export='userPermissions.indexOf("pa_chief_export")>-1' :lint-title='`导出`'></export-link>
              </el-form-item>
            </div>
            <div>
<!--              <el-form-item>-->
<!--              <el-button  icon='el-icon-setting' @click='showDeptSetting' title='预览历史全部考核情况'>考核部门-->
<!--              </el-button>-->
<!--              </el-form-item>-->

              <el-form-item>
                <el-radio-group size='mini' v-model='searchCondition.release_status' @change='handleTabClick'>
                  <el-radio-button label='Y'>已发布</el-radio-button>
                  <el-radio-button label='N'>编辑中</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>

        </el-form>


      </div>

      <div style='margin-bottom: 10px'>
        <el-tabs v-model='activeName' class='performance-tab' tab-position='left' @tab-click='handleTabClick'>
          <el-tab-pane label='营收绩效目标' name='revenue'>
            <PaChiefDeptRevenue ref='revenue' :edit-mode='editMode' @showed='handleShow'></PaChiefDeptRevenue>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
    <!--    考核设置-->
    <pa-dept-setting ref='refDeptSetting' />

    <!--        部门红人设置-->
    <DeptKolSelect ref='refDeptKolSelect' @saved='handleQuery'></DeptKolSelect>
    <DeptKolView ref='refDeptKolView' @saved='handleQuery' @closed='handleQuery'></DeptKolView>
    <!--    选择初始化MCN-->
    <el-dialog
      title='选择初始化MCN'
      :visible.sync='dialogVisible'
      width='660px'
    >
      <div class='shadow-effect' style='padding: 10px;'>
        <div style='border-bottom: #f2f2f2 1px solid;gap: 10px;margin-bottom: 20px;padding-bottom: 20px'
             class='flex-sb'>
          <div>
            <h4>选择考核的MCN中心（自由选取）</h4>
            <DeptCascader ref='refSingleDeptSelect' :show-first-group='false' :multiple='false'
                          @handleSelect='selectDept'></DeptCascader>
          </div>
          <div>
            <h4>选择的默认考核MCN中心（默认推荐）</h4>
            <el-tag
              style='margin-right: 10px;margin-bottom: 10px;cursor: pointer'
              effect='plain'
              size='small'
              v-for='tag in deptDefault'
              :key='tag'
              @click='handleDefault(tag)'
            >
              {{ tag }}
            </el-tag>
          </div>
        </div>
        <div>
          <h3>{{ searchCondition.year }}年第{{ searchCondition.quarter }}季度考核的MCN中心（已/待初始化）</h3>
          <el-tag
            style='margin-right: 10px;margin-bottom: 10px'
            effect='plain'
            type='success'
            size='medium'
            v-for='tag in deptRange'
            :key='tag'
            @close='handleClose(tag)'
            closable
          >
            {{ tag }}
          </el-tag>
        </div>
        <div slot='footer' class='dialog-footer' style='text-align: center;padding: 10px'>
          <!--          <el-button @click='dialogVisible = false' size='small'>取 消</el-button>-->
          <el-button round @click='handleInit' type='primary' size='medium' :disabled='loadingSync'
                     :loading='loadingSync'>
            初始化
          </el-button>
        </div>
      </div>

      <div style='text-align: right;margin-top: 20px'>
        <el-link icon='el-icon-document' type='primary' @click='showAllSetting'>查看《部门考核》历史记录</el-link>
      </div>

    </el-dialog>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ExportLink from '@/components/export/ExportLink'
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import QuarterSelect from '@/pages/performance/components/QuarterSelect'
import EditPaChief from './EditPaChief'
import PaDeptSetting from '@/pages/pa/components/PaDeptSetting'
import PaChiefDeptRevenue from '@/pages/pa/chief/revenue/PaChiefDeptRevenue'
import DeptKolSelect from '@/pages/pa/components/DeptKolSelect'
import DeptCascader from './DeptCascader'
import LogPanel from '@/components/logs/LogPanel'
import DeptSelectTree from '@/pages/pa/components/DeptSelectTree'
import DeptKolView from '@/pages/pa/components/DeptKolView'

export default {
  name: 'PaChiefIndex',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    DeptKolView,
    DeptSelectTree,
    LogPanel,
    DeptKolSelect,
    PaChiefDeptRevenue,
    DeptCascader,
    PaDeptSetting,
    EditPaChief,
    QuarterSelect,
    PageHeaderLayout,
    ExportLink
  },
  data() {
    return {
      activeName: 'revenue',
      editMode: false,
      loadingSync: false,
      searchCondition: { year: null, quarter: 0, dept_id: null, dept_name: null, release_status: 'Y' },
      quarters: [
        { label: '未选季度', value: 0 },
        { label: '第1季度', value: 1 },
        { label: '第2季度', value: 2 },
        { label: '第3季度', value: 3 },
        { label: '第4季度', value: 4 }
      ],
      activeMap: { 'trend': '涨粉', 'revenue': '营收' },
      deptRange: ['MCN第一中心', 'MCN第二中心'],
      deptDefault: ['MCN第一中心', 'MCN第二中心'],
      mcnDeptList: [],
      dialogVisible: false
    }
  },
  created() {

  },
  mounted() {
    this.defaultQuarter()
  },
  methods: {
    showInitSetting() {
      this.dialogVisible = true
      this.defaultSelectMcn()
    },
    showAllSetting() {
      this.dialogVisible = false
      this.showDeptSetting()
    },
    //默认选中部门
    async defaultSelectMcn() {
      // this.$refs['refCompanySelect'].init(['51361153'])
      let { list } = await this.$api.getPaMcnList(this.searchCondition)
      if (list) {
        this.deptRange = list.map((item) => {
          return item.dept_name
        })
      }
    },
    async handleInit() {
      this.loadingSync = true
      setTimeout(() => {
        this.loadingSync = false
      }, 2000)

      let params = {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        mcn_checked: this.deptRange
      }

      await this.$api.initPaChiefMcnQuarterData(params)

      this.loadingSync = false
      // this.dialogVisible = false
      setTimeout(() => {
        this.dialogVisible = false
      }, 1000)
      this.reset()
    },
    defaultQuarter() {
      //当前季度
      this.searchCondition.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.searchCondition.quarter = this.$utils.getCurrentQuarter()
      // this.loadMcnDeptList()
      this.changeDeptTree()
    },
    reset() {
      this.searchCondition = {
        year: null, quarter: 0, dept_id: null, dept_name: null, release_status: 'Y'
      }
      this.defaultQuarter()
      this.handleQuery()
    },
    changeEditMode() {
      this.editMode = !this.editMode
    },
    handleQuery() {
      this.handleTabClick()

    },
    //tab切换事件
    handleTabClick() {
      if (this.$refs['revenue'])
        this.$refs['revenue'].show(this.searchCondition)
    },
    handleShow(val) {
      console.log(val)
    },
    showDeptKolSetting() {
      // this.$refs['refDeptKolSelect'].show(this.searchCondition)
      this.$refs['refDeptKolView'].show(this.searchCondition)
    },
    showDeptSetting() {
      this.$refs['refDeptSetting'].show(this.searchCondition)
    },
    changeDeptTree() {

      this.$nextTick(() => {
        if (this.$refs['deptTree'])
          this.$refs['deptTree'].show(this.searchCondition)
      })

    },
    changeQuarter() {
      this.searchCondition.dept_id = null
      this.searchCondition.dept_name = null
      this.changeDeptTree()
      // this.loadMcnDeptList()
    },
    async loadMcnDeptList() {
      //加载MCN中心部门
      if (this.searchCondition.year && this.searchCondition.quarter) {
        let { list } = await this.$api.getPaDeptList({
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter
        })
        this.mcnDeptList = list || []
        //默认选中第一个
        if (list && list[0]) {
          this.searchCondition.dept_id = list[0].dept_id
          this.searchCondition.dept_name = list[0].dept_name
          if (this.searchCondition.dept_id)
            this.handleQuery()
        }
      } else {
        this.$notify.info('请选择年份和季度，然后刷新MCN中心')
      }
    },
    handleChangeDept(obj) {
      this.searchCondition.dept_id = obj.dept_id || null
      this.searchCondition.dept_name = obj.dept_name || ''
      this.handleQuery()

    },
    handleClose(tag) {
      this.deptRange.splice(this.deptRange.indexOf(tag), 1)
    },
    selectDept(nodeId) {
      // console.log('nodeIds', nodeId)
      if (nodeId) {
        let mcnName = this.$refs['refSingleDeptSelect'].getNodeName(nodeId)
        if (this.deptRange.indexOf(mcnName) === -1)
          this.deptRange.push(mcnName)
      }
    },
    handleDefault(mcnName) {

      if (this.deptRange.indexOf(mcnName) === -1)
        this.deptRange.push(mcnName)
    },
    async exportExcel() {
      //
      try {
        let fileName = `【总监绩效】${this.searchCondition.year}年第${this.searchCondition.quarter}季度${this.activeMap[this.activeName]}绩效目标`

        let response = await this.$api.exportPaChiefRevenueQuarterData(this.searchCondition)
        this.$notify.warning(fileName)
        this.$refs['export'].exportSaveXlsx(response, fileName)


      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.$refs.export.hideLoading()
      }
    }
  }
}
</script>

<style scoped>
.edit-box {
  margin: 10px 10px;
  padding: 10px 20px;
  width: clamp(900px, 50%, 100%);
  /*border: #e97ca1 1px dashed;*/
  /*width: 800px;*/
  /*min-width: 50%;*/
  /*max-width: 100%;*/
}
</style>
