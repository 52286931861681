<template>
  <div>
    <div v-show="visible" :style="{left:left+'px',top:top+'px'}" class="pa-quotient " ref="refQuotient">
      <div style="margin-left: 0">
        <el-tooltip effect="dark" content="拖动" placement="top-start">
          <span class="drag-handle" @mousedown="mouseDownHandle($event)"
                @mouseup="mouseUpHandle($event)"><i
              class="el-icon-rank"></i></span>
        </el-tooltip>


        <div style='padding: 10px'>
          <div class="title" style='margin: 5px;'>批量修改 -{{ formQuotientTitle }}</div>
          <el-form :inline="true" :model="formQuotient" label-width="100px">
            <el-form-item :label="formQuotient.type === 'quotient' ? '系数' : '目标'">
              <template slot='label'><span style='font-size: 14px;font-weight: bold'>{{formQuotient.type === 'quotient' ? '系数' : '目标'}}</span></template>
              <editable-cell
                :show-input="true"
                v-model="formQuotient.data"
                :placeholder="`${formQuotient.type !== 'quotient' ?'小数位保留2位':'系数最大99.99'} `"
                :is-input-number="false" style="width: 140px">
                <span slot="content">
                  <span>{{ formQuotient.data }}</span>
                  <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
                </span>
              </editable-cell>
            </el-form-item>
            <!--          <el-form-item>-->
            <!--            <el-button type="primary" @click="editQuotientCol" size="mini">确定修改</el-button>-->
            <!--          </el-form-item>-->
          </el-form>
        </div>

        <div style="text-align: center;width: 100%;margin-top: 10px">
          <el-button type="primary" size="mini" @click="save">保存</el-button>
          <el-button size="mini" @click="closeMenu">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'

export default {
  name: "QuotientEdit",
  components: {
    EditableCell
  },
  props: {
    performanceType: {
      type: String,
      require: true
    }
  },
  computed: {
    formQuotientTitle() {
      return `【${this.formQuotient.year}年${this.formQuotient.month}月】绩效${this.formQuotient.type === 'quotient' ? '系数' : '目标'}`
    }
  },
  data() {
    return {
      refName:'refPanelQuotient',
      showEditIcon: true,
      moveDataelse: {
        x: null,
        y: null
      },
      //start:鼠标右键菜单
      visible: false,
      top: 0,
      left: 0,
      //end:鼠标右键菜单
      currentRow: {},
      currentColumn: {},
      formQuotient: {
        data: 0,
        month: 0,
        year: 0,
        group_id: '',
        type: ''
      }
    }
  },
  created() {

  },
  methods: {
    async init(top, left, formQuotient) {
      this.formQuotient = formQuotient
      this.top = top
      this.left = left
      this.visible = true
    },

    closeMenu() {
      this.visible = false
      this.formQuotient = {
        data: 0,
        month: 0,
        year: 0,
        group_id: '',
        type: ''
      }

    },
    mouseDownHandle(event) {
      this.$nextTick(() => {
        this.moveDataelse.x = event.pageX - this.$refs.refQuotient.offsetLeft
        this.moveDataelse.y = event.pageY - this.$refs.refQuotient.offsetTop
        event.currentTarget.style.cursor = 'move'
        window.onmousemove = this.mouseMoveHandle
      })

    },
    mouseMoveHandle(event) {
      this.$nextTick(() => {
        let moveLeft = event.pageX - this.moveDataelse.x + 'px'
        let moveTop = event.pageY - this.moveDataelse.y + 'px'
        this.$refs.refQuotient.style.left = moveLeft
        this.$refs.refQuotient.style.top = moveTop
      })

    },
    mouseUpHandle(event) {
      window.onmousemove = null
      event.currentTarget.style.cursor = 'move'
    },
    save() {
      //修改系数列
      this.$emit('savedEvent', this.formQuotient)
      this.closeMenu()
    },
  }
}
</script>

<style lang="scss" scoped>
.pa-quotient  {
  opacity: 0.9;
  background-color: rgba(255, 255, 255,1);
  border: solid 1px #f2f2f2;
  margin: 0;
  //background: #fff;
  z-index: 9999;
  position: absolute;
  transform: translate(-40% ,-10%);
  list-style-type: none;
  padding: 15px 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  min-width: 300px;


  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    border-top: solid 1px #f2f2f2;
  }

  li {
    margin: 5px 0 2px 0;
    padding: 7px 10px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 49, 118, 0.8);
    }
  }

  .title {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    padding-inline-start: 12px;
    padding-inline-end: 10px;
    margin-bottom: 20px;
    margin-inline-start: 1px;
    margin-inline-end: 10px;
  }

  .drag-handle {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }

}
</style>
