<template>
  <div style='display: flex;flex-direction: row;justify-content:flex-start;gap: 1px'>
    <el-cascader
      style='width: 100%'
      v-bind='$attrs'
      v-on='$listeners'
      v-model='value'
      :options='options'
      :placeholder='`请选择部门（${searchCondition.year}年第${searchCondition.quarter}季度）`'
      :props="{ expandTrigger: 'hover',value: 'dpt_id', label: 'name', multiple:false,emitPath:false,checkStrictly: true}"
      @change='handleChange'
    ></el-cascader>
    <el-link icon='el-icon-refresh' @click='getOptions'></el-link>
  </div>
</template>

<script>
export default {
  name: 'DeptSelectTree',
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
    onlyDept: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      value: null,
      data: [],
      options: [],
      firstOption: {}
    }
  },
  methods: {
    show() {
      this.getOptions()
    },
    async getOptions() {
      if (this.searchCondition.year && this.searchCondition.quarter) {
        // this.$notify.info(`${this.searchCondition.year}年 第${this.searchCondition.quarter}季度`)
        let { list } = await this.$api.getPaDeptSettingOptions({
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter,
          onlyDept: this.onlyDept
        })
        this.data = list || []
      } else {
        this.data = []
      }
      this.calcOptions()

    },
    calcOptions() {
      this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
      // console.log(this.options)
      if (this.options.length > 0) {
        let dept = this.options[0]
        //计算第一个项目组
        this.calcLastDept(dept)
      }
    },
    getNodeName(val) {
      let obj = this.data.find(value => value.dpt_id === val)
      return obj ? obj.name : ''
    },
    handleChange(val) {
      const dept_name = this.getNodeName(val)
      this.$emit('handleSelect', { dept_id: val, dept_name: dept_name })
      return val
    },
    calcLastDept(dept) {
      // console.log(dept)
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        //单选
        this.value = dept.dpt_id || null
        // let deptSelect = this.data.find(value => value.dpt_id === this.value)
        // console.log(deptSelect)
        this.$emit('handleSelect', { dept_id: dept.dpt_id, dept_name: dept.name })
      }
    }
  }
}
</script>

<style scoped>

</style>
